import { useEffect, useRef } from 'react';

import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';
import { useRouter } from 'next/router';
import { SessionProvider } from 'next-auth/react';
import Cookies from 'universal-cookie';

import ErrorBoundary from '../components/prescriptive/ErrorBoundary';
import { UserProvider } from '../context/UserContext';
import { get30Days } from '../helpers/date-helpers';
import useTranscend from '../hooks/useTranscend';
import { checkIsDevelopment } from '../lib/_helpers';
import { initDatadogRum } from '../lib/datadog';

// initialize datadog
initDatadogRum();

const isDevelopment = checkIsDevelopment();

const reinitNavDropdowns = () => {
  const dropdowns = document.querySelectorAll('.dropdown');
  for (const dropdown of dropdowns) {
    dropdown.classList.add('-disabled');
    setTimeout(() => dropdown.classList.remove('-disabled'), 100);
  }
};

const setCookie = (cookies, key, value) => {
  cookies.set(key, value, {
    domain: window.location.hostname,
    expires: get30Days(),
    path: '/',
  });
};

export default function App(props) {
  const { Component, pageProps } = props;
  const router = useRouter();
  const { current: cookies } = useRef(new Cookies());
  const isPreviousPageSava = router.query.sava === '1';

  useEffect(() => {
    if (!isPreviousPageSava) {
      window.sessionStorage.removeItem('saved_form_values');
    }
  }, [isPreviousPageSava]);

  useTranscend();

  useEffect(() => {
    const onMountLocation = window.location.pathname;

    const previousCookie = cookies.get('intial_landing_page');
    const newCookie = cookies.get('initial_landing_page');

    // set initial landing page cookie
    if (previousCookie) {
      // Use an existing (typo) cookie
      setCookie(cookies, 'initial_landing_page', previousCookie);
    } else if (!newCookie) {
      setCookie(cookies, 'initial_landing_page', onMountLocation);
    }

    // set previous url
    setCookie(cookies, 'previous_url', onMountLocation);

    const handleRouteChangeStart = () => {
      setCookie(cookies, 'previous_url', window.location.pathname);
    };

    const handleRouteChangeComplete = () => {
      // closes our nav for us on route change...
      reinitNavDropdowns();
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, []);

  const {
    enableLivePreview, locale, session,
  } = pageProps;
  let AppContent = <Component {...pageProps} />;

  // only development builds get the session provider
  if (isDevelopment) {
    AppContent = (
      <SessionProvider session={session}>
        {AppContent}
      </SessionProvider>
    );

    if (enableLivePreview) {
      AppContent = (
        <ContentfulLivePreviewProvider
          enableInspectorMode
          enableLiveUpdates
          locale={locale}
        >
          {AppContent}
        </ContentfulLivePreviewProvider>
      );
    }
  }

  return (
    <ErrorBoundary name="global">
      <UserProvider>
        {AppContent}
      </UserProvider>
    </ErrorBoundary>
  );
}
