import Cookies from 'universal-cookie';

import { FormType } from '.';

declare global {
  interface Window {
    dataLayer?:
    { push: (event: Record<string, unknown>) => void; };
    gtag?: (
      command: 'get',
      targetId: string,
      fieldName: string,
      callback: (clientId: string) => void
    ) => void;
  }
}

export enum GAEventName {
  ButtonClick = 'button_click',
  FormError = 'form_error',
  FormStarted = 'form_started',
  PopupClose = 'popup_close',
  PopupCtaClick = 'popup_cta_click',
  PopupFormError = 'popup_form_error',
  PopupFormStart = 'popup_form_start',
  PopupFormSubmit = 'popup_form_submit',
  PopupView = 'popup_view'
}

type ClickEventPayload = {
  click_url: string;
  event: GAEventName.ButtonClick;
};

type FormEventPayload = {
  event: GAEventName.FormError | GAEventName.FormStarted;
  form_type: FormType;
};

type PopupEventPayload = {
  event: GAEventName.PopupClose | GAEventName.PopupView;
  popup_name: string;
};

type PopupCtaEventPayload = {
  event: GAEventName.PopupCtaClick;
  popup_cta_name: string;
  popup_name: string;
};

type PopupFormEventPayload = {
  event: GAEventName.PopupFormError | GAEventName.PopupFormStart | GAEventName.PopupFormSubmit;
  form_type: FormType;
  popup_name: string;
};

type GAEventPayload = ClickEventPayload |
FormEventPayload |
PopupCtaEventPayload |
PopupEventPayload |
PopupFormEventPayload;

export const sendGAEvent = (payload: GAEventPayload) => {
  if (typeof window === 'undefined' || !window.dataLayer) {
    return;
  }

  window.dataLayer.push(payload);
};

export function getClientId(cb: (clientId: string) => void) {
  const measurementId = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

  if (!measurementId) {
    console.error('Measurement ID is not defined');

    return null;
  }

  if (typeof window.gtag !== 'function') {
    console.warn('gtag is not defined');

    return null;
  }

  // Assign to a local variable
  const gtag = window.gtag;

  gtag('get', measurementId, 'client_id', cb);
}

export const getGASessionId = ({ gaSessionKey = '' }) => {
  const cookies = new Cookies();
  const value = cookies.get(gaSessionKey) ?? ''; // _ga_<container_id>
  const pattern = /GS\d\.\d\.(.+?)(?:;|$)/;
  const match = value.match(pattern);

  if (match && match[1]) { return match[1].split('.')[0] ?? ''; }

  return '';
};
